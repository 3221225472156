<template>
    <v-container fluid style="padding-bottom:80px">
      <v-row>
        <v-col cols="12">
            <v-breadcrumbs :items="breadcumbs">
                <template v-slot:item="{ item }">
                    <v-breadcrumbs-item
                        :to="item.href"
                        class="text-subtitle-2 crumb-item"
                        :disabled="item.disabled"
                        exact
                    >
                        {{ item.text }}
                    </v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
        </v-col>
      </v-row>
      <v-row>
            <v-col cols="12">
                <!-- <v-card> -->
                    <v-card-title class="pb-1 pt-1">
                        Filter
                    </v-card-title>
                    <v-progress-linear
                        :active="loading"
                        :indeterminate="loading"
                        absolute
                        bottom
                        color="deep-purple accent-4"
                    ></v-progress-linear>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" xs="12" sm="5" md="3">
                                <v-autocomplete
                                    solo
                                    clearable
                                    v-model="office"
                                    :items="offices"
                                    item-value="office_id"
                                    item-text="office"
                                    label="Office"
                                    @change="(event) => updateSales(event)"
                                    class="ma-0 pa-0"
                                    hide-details=true
                                    :disabled="disabled"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="3">
                                <v-autocomplete
                                    solo
                                    clearable
                                    v-model="sales"
                                    :items="saleses"
                                    item-value="sales_id"
                                    item-text="nama"
                                    label="Sales"
                                    class="ma-0 pa-0"
                                    hide-details=true
                                    :disabled="disabled_sales"
                                ></v-autocomplete>
                                <v-progress-linear
                                    :active="loading2"
                                    :indeterminate="loading2"
                                    absolute
                                    bottom
                                    color="deep-purple accent-4"
                                ></v-progress-linear>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-btn class="mt-1" 
                                color="info" elevation="2" large @click="search()">Search</v-btn>
                            </v-col>
                            <!-- <v-col cols="12" xs="12" sm="5" md="3">
                                <v-btn
                                    class="mr-2 mt-1"
                                    color="error"
                                    elevation="2"
                                    large
                                    rounded
                                    @click="search()"
                                >Search</v-btn>
                            </v-col> -->
                        </v-row>
                    </v-container>
                <!-- </v-card> -->
            </v-col>
            <v-col cols="12" style="padding-bottom: 100px">
                <v-card>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-card-title>Result
                                    <v-spacer></v-spacer>
                                </v-card-title>
                                <v-data-table
                                    :headers="headers"
                                    :items="purchases"
                                    :options.sync="options"
                                    :loading="loading"
                                    loading-text="Please wait, retrieving data"
                                    :server-items-length="totalItems"
                                    :page.sync="pagination"
                                    page-count="10"
                                    class="elevation-1"
                                >
                                    <template v-slot:[`item.activity`]="{ item }">
                                        <div v-html="item.activity">

                                        </div>
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-icon small class="mr-2" @click="showItem(item)" v-on="on">
                                                    mdi-eye
                                                </v-icon>
                                            </template>
                                            <span>View Map</span>
                                        </v-tooltip>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
                <v-dialog v-model="dialog" max-width="1000px">                
                    <v-card>
                        <v-card-title>Detail Location</v-card-title>
                        <v-card-text>
                             <v-container fluid>
                                <v-row>
                                    <v-col cols="12">
                                        <div id="map_canvas" style="height:432px; width:100%; border: 1px solid grey;"></div>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                    <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                    </v-layout>
                    <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                    </v-btn>
                </v-snackbar>
            </v-col>
      </v-row>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'KBT',
                disabled: false,
                href: '/admin/kbt',
                },
                {
                text: 'Sales Activity',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Today Visit',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            loading: false,
            offices: [],
            office: '',
            saleses: [],
            sales: '',
            display: 'none',
            purchases: [],
            headers: [
                { text: 'Id Cust', align: 'start',
                    sortable: true, value: 'customerid' },
                { text: 'Customer', value: 'namacustomer' },
                { text: 'Sales', value: 'salesid' },
                { text: 'Address', value: 'alamat' },
                { text: 'City', value: 'city' },
                { text: 'Date', value: 'tr_date' },
                { text: 'Activity', value: 'activity' },
                { text: 'Remark', value: 'remark' },
                { text: 'Actions',  value: 'actions', sortable: false },
            ],
            totalItems: 10,
            options: {},
            loading2: false,
            search_detail: '',
            po_items: [],
            po_item: '',
            option_po_items: {},
            loading3: false,
            loading4: false,
            loading5: false,
            totalItem_po_items: 10,
            dialog: false,
            data_var: {
                entity_id : 'KBT',
                appl_id : 'SALES ACTIVITY'
            },
            user_approve1: '',
            user_approve2: '',
            pagination: 1,
            mymap: '',
            data_var: {
                entity_id : 'KBT',
                appl_id : 'WEBKBT'
            },
            group: '',
            disabled: false,
            disabled_sales: false
        }
    },
    mounted(){
        this.getOffice()
        this.getEnvConf()
    },
    methods:{
        getEnvConf(){
            let data = this.data_var;

            this.$store.dispatch("getVariabelUser", { data }).then(res => {

                for (let i = 0; i < res.data.data.length; i++) {

                    if (res.data.data[i]['var_id'] === 'OFFICEID') {
                        if (res.data.data[i]['var_value'] != null) {
                            this.office = res.data.data[i]['var_value']
                            // this.getOffice()
                            this.disabled = true

                            this.updateSales(this.office)
                        }
                    }

                    if (res.data.data[i]['var_id'] === 'KOORDINATOR_SALES') {
                        if (res.data.data[i]['var_value'] != null) {
                            this.group = 'KOORDINATOR_SALES'
                        }
                    }
                    
                    if (this.group === 'KOORDINATOR_SALES') {
                        if (res.data.data[i]['var_id'] === 'SALESID' && this.group === 'KOORDINATOR_SALES') {
                            if (res.data.data[i]['var_value'] != null) {
                                // this.updateSales(this.office)
                                this.sales = res.data.data[i]['var_value']
                            }
                        } else{
                            if (res.data.data[i]['var_id'] === 'SALESID') {
                                if (res.data.data[i]['var_value'] != null) {
                                    // this.updateSales(this.office)
                                    this.sales = res.data.data[i]['var_value']
                                    this.disabled_sales = true
                                }
                            }
                        }
                    } else{
                        if (res.data.data[i]['var_id'] === 'SALESID') {
                            if (res.data.data[i]['var_value'] != null) {
                                // this.updateSales(this.office)
                                this.sales = res.data.data[i]['var_value']
                                this.disabled_sales = true
                            }
                        }
                    }

                    
                }

                this.getPullData(1, 10)

            })
        },
        search(){
            this.pagination = 1
            this.getPullData(1, 10, true)
        },
        close() {
            this.dialog = false
        },
        async showItem(item){
            this.po_item = item
            this.dialog = true
            this.loading = true

            if (this.mymap != '') {
                this.mymap.off();
                this.mymap.remove();
            }

            await axios.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${this.po_item.sales_latitude}&lon=${this.po_item.sales_longitude}`)
            .then(res => {
                this.loading = false
                var address = res.data.address.village +', '+ res.data.address.state_district +', '+ res.data.address.state;

                this.mymap = L.map('map_canvas').setView([this.po_item.sales_latitude, this.po_item.sales_longitude], 13);
        
                L.tileLayer(`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${process.env.TK_KBT}`, {
                    maxZoom: 18,
                    attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
                        '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
                        'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                    id: 'mapbox/streets-v11',
                    tileSize: 512,
                    zoomOffset: -1
                }).addTo(this.mymap);

                L.marker([this.po_item.sales_latitude, this.po_item.sales_longitude],
                    {draggable: true,        // Make the icon dragable
                    //title: 'Hover Text',     // Add a title
                    opacity: 0.5}            // Adjust the opacity
                    )
                    .addTo(this.mymap)
                    .bindPopup('<b>Your Sales Visit location</b><br>'+address)
                    .openPopup();

            });
        },
        async getPullData(page = 1, itemsPerPage = 10, search){
            this.loading = true

            await axios.get(`${process.env.VUE_APP_URL}/api/kbt/sales_activity/today?salesid=${this.sales ? this.sales : ""}&txtOffID=${this.office ? this.office : ""}&page=${page > 1 ? page : ''}&pagination=${itemsPerPage}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.display = 'block'
                this.loading = false
                this.purchases = res.data.data
                this.totalItems = res.data.meta.total
                this.pagination = res.data.meta.current_page
            })
        },
        async getOffice(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kbt/piutang`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.offices = res.data.office
                this.saleses = res.data.sales
            });
        },
        async updateSales(value){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kbt/piutang?officeid=${this.office ? this.office : ""}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.saleses = res.data.sales
            });
        }
        
    },
    watch: {
        options: {
            handler () {
                const { page, itemsPerPage } = this.options
                if (this.display === 'block') {
                    this.getPullData(page, itemsPerPage, true)
                }
            },
            deep: true,
        }
    }
}
</script>